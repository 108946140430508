<template>
  <div>
    <b-dropdown
      size="lg"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      v-if="instituicao"
    >
      <template #button-content>
        <i class="fas fa-ellipsis-v"/>
      </template>
      <b-dropdown-item v-if="permissao.showCancelar && !professor" class="click-a">
        <a
          @click.prevent="fluxoStatus('Rascunho', 'rascunho')"
          class="dropdown-item"
          type="button"
        >
          <i>
            <img :src="require('../../assets/images/acoes/cancelar.svg')"
          /></i>
          Reprovar
        </a>
      </b-dropdown-item>
      <b-dropdown-item v-if="permissao.showDisponibilizar && !professor" class="click-a">
        <a
          class="dropdown-item"
          type="button"
          @click.prevent="fluxoStatus('Disponibilizado', 'disponibilizado')"
        >
          <i><img :src="require('../../assets/images/acoes/liberar.svg')" /></i
          >Aprovar
        </a>
      </b-dropdown-item>
      <b-dropdown-item v-if="permissao.showPreview" class="click-a">
        <a
          class="dropdown-item"
          type="button"
          v-on:click="$emit('showModalPreview', true)"
        >
          <i><img :src="require('../../assets/images/acoes/preview.svg')" /></i
          >Revisar
        </a>
      </b-dropdown-item>
    </b-dropdown>

    <b-dropdown
      size="lg"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      v-if="!instituicao"
    >
      <template #button-content>
        <i class="fas fa-ellipsis-v"/>
      </template>
      <b-dropdown-item class="click-a">
        <router-link
          :to="{ name: 'provas_edit', params: { id: prova.id } }"
          v-if="permissao.showEditar"
        >
          <a class="dropdown-item" type="button" v-if="edit">
            <i><img :src="require('../../assets/images/acoes/editar.svg')" /></i
            >Editar
          </a>
        </router-link>
      </b-dropdown-item>

      <b-dropdown-item v-if="permissao.showRevisao" class="click-a">
        <a
          class="dropdown-item"
          type="button"
          @click.prevent="fluxoStatus('EmRevisao', 'em revisão')"
        >
          <i><img :src="require('../../assets/images/acoes/enviar.svg')" /></i
          >Enviar para Revisão
        </a>
      </b-dropdown-item>

      <b-dropdown-item v-if="permissao.showCancelar" class="click-a">
        <a
          @click.prevent="fluxoStatus('Rascunho', 'rascunho')"
          class="dropdown-item"
          type="button"
        >
          <i><img :src="require('../../assets/images/acoes/cancelar.svg')" /></i
          >Reprovar
        </a>
      </b-dropdown-item>

      <b-dropdown-item v-if="permissao.showPreview" class="click-a">
        <a
          class="dropdown-item"
          type="button"
          v-on:click="$emit('showModalPreview', true)"
        >
          <i><img :src="require('../../assets/images/acoes/preview.svg')" /></i
          >Revisar
        </a>
      </b-dropdown-item>

      <b-dropdown-item v-if="permissao.showLiberarResultado" class="click-a">
        <a
          class="dropdown-item"
          type="button"
          @click.prevent="fluxoStatus('Liberado', 'liberado')"
        >
          <i><img :src="require('../../assets/images/acoes/liberar.svg')" /></i
          >Liberar Resultado
        </a>
      </b-dropdown-item>

      <b-dropdown-item v-if="permissao.showVerResultado" class="click-a">
        <a
          @click.prevent="resultados(prova)"
          class="dropdown-item"
          type="button"
        >
          <i
            ><img
              :src="require('../../assets/images/acoes/resultado.svg')" /></i
          >Ver Resultado
        </a>
      </b-dropdown-item>

      <b-dropdown-item v-if="permissao.showCorrigir" class="click-a">
        <router-link
          :to="{ name: 'provas_para_correcao', params: { id: prova.id } }"
        >
          <a
            @click.prevent="listaProvaCorrigir(prova.id)"
            class="dropdown-item"
            type="button"
          >
            <i
              ><img
                :src="require('../../assets/images/acoes/corrigir.svg')" /></i
            >Corrigir
          </a>
        </router-link>
      </b-dropdown-item>


      <b-dropdown-item v-if="permissao.showDeletar" class="click-a">
        <a
          class="dropdown-item"
          type="button"
          @click.prevent="deletar(prova.id)"
        >
          <i><img :src="require('../../assets/images/acoes/deletar.svg')" /></i
          >Deletar
        </a>
      </b-dropdown-item>

      <b-dropdown-item v-if="permissao.showDisponibilizar" class="click-a">
        <a
          class="dropdown-item"
          type="button"
          @click.prevent="fluxoStatus('Disponibilizado', 'disponibilizado')"
        >
          <i><img :src="require('../../assets/images/acoes/liberar.svg')" /></i
          >Disponibilizar
        </a>
      </b-dropdown-item>

      <b-dropdown-item
        v-if="prova.statusCodigo == 'EmRevisao' && instituicao"
        class="click-a"
      >
        <a
          class="dropdown-item"
          type="button"
          @click.prevent="fluxoStatus('Aprovado', 'aprovado')"
        >
          <i><img :src="require('../../assets/images/acoes/liberar.svg')" /></i
          >Disponibilizar
        </a>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { constantes } from "@/constants";
import { checkUserHaveRole } from "@/tools";

export default {
  data() {
    return {
      showMenu: false,
      showModalPreview: false,
      statusProva: constantes,
      instituicao: false,
      edit: false,
      professor: false,
      permissao: {
        showAgendardados: false,
        showEditar: false,
        showRevisao: false,
        showCancelar: false,
        showPreview: false,
        showLiberarResultado: false,
        showVerResultado: false,
        showCorrigir: false,
        showImprimir: false,
        showDuplicar: false,
        showDeletar: false,
        showDisponibilizar: false,
      },
    };
  },
  created() {
    if (checkUserHaveRole([constantes.ROLE_INSTITUICAO])) {
      this.instituicao = true;
    }
    console.log(checkUserHaveRole([constantes.ROLE_PROFESSOR]));
    if (checkUserHaveRole([constantes.ROLE_PROFESSOR])) {
      this.edit = true;
      this.professor = true;
    } else {
      this.professor = false;
      this.edit = false;
    }

    this.$api
      .get("/provas-permissao-acao/" + this.prova.statusCodigo)
      // .get("/provas-permissao-acao/Aprovado")
      .then((response) => {
        var dados = response.data;
        console.log(dados);
        this.permissao.showAgendar = dados.agendar.permissao;
        this.permissao.showEditar = dados.editar.permissao;
        this.permissao.showRevisao = dados.revisao.permissao;
        this.permissao.showCancelar = dados.cancelar.permissao;
        this.permissao.showPreview = dados.preview.permissao;
        this.permissao.showLiberarResultado = dados.liberarResultado.permissao;
        this.permissao.showVerResultado = dados.verResultado.permissao;
        this.permissao.showCorrigir = dados.corrigir.permissao;
        this.permissao.showImprimir = dados.imprimir.permissao;
        this.permissao.showDuplicar = dados.duplicar.permissao;
        this.permissao.showDeletar = dados.deletar.permissao;
        this.permissao.showDisponibilizar = dados.disponibilidar.permissao;
      });
  },
  props: {
    prova: {
      type: Object,
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    previewProva() {},
    listaProvaCorrigir(index) {
      this.$router.push({
        name: "provas_para_correcao",
        params: { id: index },
      });
    },
    fluxoStatus(codigo, descricao) {
      this.toggleMenu();
      if(!descricao){
        descricao = codigo;
      }

      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma mudar o status dessa prova para " + descricao + "?",
        // text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonClass: "proj-botao-padrao",
        cancelButtonColor: "#999",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");

          this.$api
            .post("/provas-fluxo-status/" + this.prova.id, {
              status: codigo,
              prova: this.prova.id,
            })
            .then((response) => {
              console.log(response);
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                icon: "success",
                title: "Prova " + descricao + "!",
                showConfirmButton: false,
                timer: 1500,
                position: "center",
              }).then(() => {
                location.reload();
                this.$root.$emit("Spinner::hide");
              });
              setTimeout(function () {
                location.reload();
              }, 2000);
            })
            .catch((error) => {
              console.log(error.response);
              this.$root.$emit("Spinner::hide");
              if (error.response.status == 500) {
                Swal.fire({
                  icon: "success",
                  title: "Prova " + descricao + "!",
                  showConfirmButton: false,
                  timer: 1500,
                  position: "center",
                }).then(() => {
                  location.reload();
                });
                setTimeout(function () {
                  location.reload();
                }, 2000);
              } else if(error.response.status == 400){
                Swal.fire({
                  icon: "error",
                  title: "Erro ao alterar status da prova!",
                  text: error.response.data.detail,
                  showConfirmButton: false,
                  timer: 7000,
                  position: "center",
                })
              }
            });
        }
      });
    },
    resultados(prova) {
      this.$router.push("/resultados-alunos/" + prova.id);
    },
    pad(value) {
      return value.toString().padStart(2, 0);
    },
    duplicate(prova) {
      this.toggleMenu();

      this.$root.$emit("Spinner::show");

      console.log(this.prova);
      let d = new Date();
      let date = `${d.getFullYear()}-${this.pad(d.getMonth() + 1)}-${this.pad(
        d.getDate()
      )}T${this.pad(d.getHours())}:${this.pad(d.getMinutes())}:${this.pad(
        d.getSeconds()
      )}.000Z`;

      let provaCopia = {
        prova: prova.id,
        inicio: date,
      };
      // provaCopia.prova = prova.id
      // provaCopia.inicio = date

      console.log(provaCopia);

      this.$api
        .post("clonar-prova", provaCopia)
        .then((res) => {
          this.$root.$emit("Spinner::hide");
          console.log(res);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Prova duplicada com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(function () {
            location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          if (error.response.status == 400) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.detail +
                "</span>",
              showConfirmButton: false,
              timer: 3500,
            }).then(() => {
              // window.location.reload()
              this.showModalCreate = false;
            });
          } else if (error.response.status == 401) {
            // store.dispatch("auth/ActionSignOut");
            window._Vue.$router.push({ name: "login" });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    deletar() {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção dessa prova?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.$api
              .put("provas/" + this.prova.id + "/trash", {})
              .then((response) => {
                this.$root.$emit("Spinner::hide");

                Swal.fire({
                  icon: "success",
                  title: "Prova deletada com sucesso!",
                  showConfirmButton: false,
                  timer: 1500,
                  position: "center",
                });

                setTimeout(function () {
                  location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Ocorreu um erro.",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro no processo!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.click-a:hover {
  background: #bbfacf;
  color: #26a54e;
}
.click-a:active {
  background: #bbfacf;
}
.dropdown-menu {
  margin: none !important;
}
</style>
